import React from "react";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import SEO from "../../components/seo";

const digital = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA Digital Transformation"
          description="SWA has effectively navigated the challenges brought on by the pandemic by undergoing a digital transformation since 2021"
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              ></div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    SWA Digital Transformation journey
                  </span>
                </h1>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1676088782/digital/uwlnltnmgctqrddptn6p.jpg"
                    alt="Appeal for donation presentation deck"
                    className="w-full mx-auto "
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                {/* <h2 className="my-3 text-center">
                  Joint project by Singapore Women Association(SWA) and <br />
                  Symrise Asia Pacific Pte Ltd
                </h2> */}
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The Singapore Women's Association (SWA) has effectively
                  navigated the challenges brought on by the pandemic by
                  undergoing a digital transformation since 2020. This
                  transition has allowed the organization to maintain resilience
                  should similar situations arise and has enhanced its
                  effectiveness and efficiency in its operations.
                </p>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  One major milestone accomplishment was the resumption of the
                  Annual Charity Dinner in Oct 2022, which had been on hold for
                  two years due to safety concerns. SWA utilized its digital
                  capabilities to send electronic invitations. The appeal for
                  donations to the Annual Charity Dinner was completely online,
                  eliminating the need for costly printed materials.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1676099802/digital/iocdnz2vlwv44aqcfkpd.jpg
                    "
                    alt="Appeal for donation presentation deck"
                    className="w-full p-8 mx-auto mt-5"
                  />
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1676086094/digital/xpiaxac4utn9trmb1vfo.webp
                    "
                    alt="Appeal for donation presentation deck"
                    className="w-full p-8 mx-auto"
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  View presentation deck:
                </p>
                <a
                  href="https://www.canva.com/design/DAFKhjhq8Dg/GYbYQ8QXyWa8q6N2kknw0w/view?utm_content=DAFKhjhq8Dg&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu"
                  target="_blank"
                  rel="noreferrer"
                  className="font-extrabold text-swa-1"
                >
                  Annual Charity Dinner Appeal for Donation
                </a>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA also recruited participants and volunteers for the Charity
                  Dinner with the help of online form capabilities and conducted
                  interviews via video conferencing tools.
                </p>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  For the Project Symrise Year-end Lunch Event, event backdrop
                  was designed and displayed electronically with the help of
                  digital tools, cutting down on unnecessary printing cost.
                </p>
                <div className="w-full mx-auto my-2">
                  <img
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1676086211/digital/wwns8gmckyxwb2b7pxpq.webp
                    "
                    alt="Year-End Lunch Event"
                    className="w-full p-8 mx-auto"
                  />
                  {/* <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p> */}
                </div>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA intends to continue its digital journey by utilizing email
                  and messaging services for internal and external
                  communications. The organization is also migrating to
                  Microsoft 365 & Azure for non-profits to improve
                  communication, collaboration, and cloud hosting and storage
                  capabilities.
                </p>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The impact on the organization's regular activities supporting
                  the community and senior citizens was minimal, thanks to
                  digital adoption. For example, SWA continued to participate in
                  National Day Parade celebrations and Lunar New Year
                  festivities with various adopted Senior homes through online
                  video conferencing and conducted numerous Laughter Yoga
                  sessions online to help seniors stay engaged and happy.
                </p>

                <div className="my-5 aspect-w-16 aspect-h-9">
                  <iframe
                    width="420"
                    height="315"
                    src="https://www.youtube.com/embed/20OASsx2yJQ"
                  ></iframe>
                </div>
                <p className="text-sm text-left text-gray-600 ">
                  SWA & Ren Ci Hospital NDP Celebrations 2022
                </p>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA will soon streamline its internal invoice processing by
                  transitioning to a fully digital system, which has improved
                  the efficiency of approval and review processes, allowing the
                  completion of most tasks electronically and remotely.
                </p>

                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The organization is grateful for the grants provided by
                  various technology vendors, enabling it to leverage digital
                  technology fully. SWA will continue to adopt digital solutions
                  progressively in 2023 and beyond.
                </p>

                <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                  <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                    <span className="block">
                      Is your organisation looking to support and sponsor our
                      initiatives?
                    </span>
                    <span className="block text-swa-1">Let's discuss.</span>
                  </h2>
                  <div className="flex mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <Link
                        to="/forms/contact"
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default digital;
